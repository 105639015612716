<template>
    <div class="org-summ fade-in">
        <div class="model-header" style="font-size:24px;color:var(--gold);">הקמת ארגון חדש</div>
        <div class="setup-progress" v-if="!setup_done">
            <p class="success">המערכת מקימה את הארגון</p>
            <div class="input-explainer" style="margin-top: 25px;">
                <span class="material-symbols-rounded">contact_support</span>
                <p>התהליך יכול לקחת עד - 2 דקות.</p>
            </div>
            <div class="input-explainer" style="margin-top: 3px;">
                <span class="material-symbols-rounded">error</span>
                <p>יציאה באמצע התהליך תוביל להקמה מחדש.</p>
            </div>
            <div class="setup-step" style="margin-top: 25px;">
                <div class="check" v-loading="isCreatingProfile.loading">
                    <span class="material-symbols-rounded success" v-if="isCreatingProfile.done">check_circle</span>
                    <span class="material-symbols-rounded" v-if="!isCreatingProfile.done">pending</span>
                </div>
                <div class="desc">
                    <p>יצירת פרופיל משתמש - מנהל מערכת</p>
                </div>
            </div>
            <div class="setup-step" style="margin-top: 25px;">
                <div class="check" v-loading="isCreatingOrg.loading">
                    <span class="material-symbols-rounded success" v-if="isCreatingOrg.done">check_circle</span>
                    <span class="material-symbols-rounded" v-if="!isCreatingOrg.done">pending</span>
                </div>
                <div class="desc">
                    <p>יצירת פרופיל ארגון</p>
                </div>
            </div>
            <div class="setup-step" style="margin-top: 25px;">
                <div class="check" v-loading="isCreatingOs.loading">
                    <span class="material-symbols-rounded success" v-if="isCreatingOs.done">check_circle</span>
                    <span class="material-symbols-rounded" v-if="!isCreatingOs.done">pending</span>
                </div>
                <div class="desc">
                    <p>יצירת מערכת הפעלה חדשה</p>
                </div>
            </div>
            <div class="setup-step" style="margin-top: 25px;">
                <div class="check" v-loading="isCreatingDone.loading">
                    <span class="material-symbols-rounded success" v-if="isCreatingDone.done">check_circle</span>
                    <span class="material-symbols-rounded" v-if="!isCreatingDone.done">pending</span>
                </div>
                <div class="desc">
                    <p>בדיקה וסיום</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import {onMounted,computed} from '@vue/runtime-core';
import { async } from 'q';
import { projectFunctions } from '../../../../Firebase/config';
import { slide_pop_error } from '../../../../Scripts/Msgs';
import store from '../../../../store';

export default {
setup(){
    const user = ref(computed(() => {
        return store.state.user
    }))
    const isCreatingProfile = ref({
        loading: false,
        done: false
    });
    const isCreatingOrg = ref({
        loading: false,
        done: false
    });
    const isCreatingOs = ref({
        loading: false,
        done: false
    });
    const isCreatingDone = ref({
        loading: false,
        done: false
    });

    const setup_done= ref(computed(() => {
        if(!isCreatingOrg.value.done || !isCreatingOs.value.done || !isCreatingProfile.value.done || !isCreatingDone.value.done){
            return false
        }
        return true
    }))

    const setup_flow = async () => {
        //--------Profile
        isCreatingProfile.value.loading = true;
        const res = await projectFunctions.httpsCallable("setup_new_profile")({data: store.state.temp_obj})
        .catch(e => {
            slide_pop_error("המערכת נתקלה בשגיאה טכנית, אנא המתן מספר דקות ונסה שוב או פנה לתמיכה טכנית.", 5000);
        })
        if(res){
            console.log('check_res:',res);
            isCreatingProfile.value.loading = false;
            isCreatingProfile.value.done = true;
        }else{
            slide_pop_error("שגיאה בעת הקמת פרופיל, אנא חזור ועדכן את הטופס.", 5000);
        }
        
        isCreatingOrg.value.loading = true;
        const res_1 = await projectFunctions.httpsCallable("setup_new_organization")({data: store.state.temp_obj})
        .catch(e => {
            slide_pop_error("המערכת נתקלה בשגיאה טכנית, אנא המתן מספר דקות ונסה שוב או פנה לתמיכה טכנית.", 5000);
        })
        if(res_1){
            isCreatingOrg.value.loading = false;
            isCreatingOrg.value.done = true;
        }else{
            slide_pop_error("שגיאה בעת הקמת פרופיל, אנא חזור ועדכן את הטופס.", 5000);
        }

    }

    onMounted(() => {
        setup_flow();
    });

    return{
        isCreatingProfile, isCreatingOrg, isCreatingOs,
        setup_done, isCreatingDone
    }
}
}
</script>

<style scoped>
.org-summ{
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 80px 5px 0 5px;
    flex-shrink: 0;
    height: auto;
    position: relative;
}
.setup-progress{
    width: 100%;
    height: fit-content;
    overflow: hidden;
    margin-top: 25px;
    border-radius: 10px;
    font-size: 18px;
    color: white;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.356);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.setup-step{
    width: 100%;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    margin-top: 15px;
    position: relative;
    flex-shrink: 0;
}
.setup-step .check{
    width: 60px;
    height: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.589);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
}
.setup-step .desc{
    width: calc(100% - 60px);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    padding-right: 10px;
}
.setup-step .desc p {
    font-weight: 300;
}
.success{
    color: var(--success);
}
</style>